import EditClusterModal from './EditClusterModal';
import EditLocationModal from './EditLocationModal';
import EditPanelModal from './EditPanelModal';
import EditVehicleModal from './EditVehicleModal';
import EditRFIDModal from './EditRFIDModal';
import EditUserModal from './EditUserModal';

export default function Modals() {
    return (
        <>
            <EditClusterModal />
            <EditLocationModal />
            <EditPanelModal />
            <EditVehicleModal />
            <EditRFIDModal />
            <EditUserModal />
        </>
    );
}
